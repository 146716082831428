import React from 'react'
import PropTypes from 'prop-types'
import VideoPlayer from 'src/components/shared/VideoPlayer'
import styles from './index.module.scss'
import RichText from '../../../../../shared/RichText'
import { generateElementId } from '../../../../../../common/helpers'

const VideoItem = ({ item, reverse }) => {
  return (
    <div
      className={`${styles.videoItem} bg-blue-base-white py-12.2 ${
        reverse ? 'lg:bg-white' : 'lg:bg-blue-base-white'
      }`}
      id={`video-item-${item.title}`}
    >
      <div
        className={`gap-x-primary padding-x-primary grid grid-cols-4 items-center lg:grid-cols-12`}
        data-testid="video-story-item"
      >
        <div
          className={`order-last col-span-4 flex flex-col pt-8 text-center lg:pt-0 lg:text-left ${
            reverse
              ? 'lg:order-last lg:col-start-8'
              : 'lg:order-first lg:col-start-2'
          }`}
        >
          <p className="text-13 font-normal leading-5 text-gray-235 lg:text-15 lg:font-medium lg:leading-6.5">
            {item.tagline}
          </p>
          <h3 className="py-4 text-23 font-medium leading-8 lg:text-2.5xl lg:leading-10">
            {item.title}
          </h3>
          <div className={`text-15`}>
            <RichText
              contentData={item.description}
              className={styles.videoItem}
            />
          </div>
        </div>
        <div
          className={`col-span-full lg:col-span-5 ${
            reverse ? 'lg:col-start-2 ' : 'lg:col-start-7'
          }`}
          data-testid="video-item"
        >
          <div id={`video-player-${generateElementId(item.videoUrl)}`}>
            <VideoPlayer
              url={item.videoUrl}
              light={item.videoImage}
              className={`h-56 object-top md:h-80`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

VideoItem.propTypes = {
  item: PropTypes.object.isRequired,
  reverse: PropTypes.bool.isRequired,
}

export default VideoItem
